@import 'utilities/variables';

.player_container {
  font-size: 3rem;
  text-align: left;
  width: 100%;
  background: $primary-dark;
  display: flex;
  flex-direction: column;
  padding: 4rem 4rem;

  @media screen and (min-width: $small-bp) {
    flex-direction: row;
    padding: 4rem 16rem 4rem 8rem;
  }

  h1 {
    font-size: 8rem;
    text-align: center;

    @media screen and (min-width: $small-bp) {
      text-align: left;
    }
  }

  .player_profile {
    margin: 0rem auto;
    width: 23rem;

    @media screen and (min-width: $small-bp) {
      margin: 2rem 5rem 0rem 0rem;
      width: 30rem;
    }

    img {
      width: 100%;
    }
  }

  .player_content {
    flex: 1;

    @media screen and (min-width: $small-bp) {
      margin-top: 4rem;
    }
  }

  .player_social_links {
    display: inline-block;
    margin: 1rem 0rem 2rem 0rem;
    width: 100%;
    text-align: center;

    @media screen and (min-width: $small-bp) {
      text-align: left;
    }

    .player_social_link {
      font-size: 2rem;
      display: block;
      color: $primary-light;

      @media screen and (min-width: $medium-bp) {
        display: inline-block;
      }
    }

    .player_social_link + .player_social_link {
      margin: 2rem 0rem;
      @media screen and (min-width: $medium-bp) {
        margin-left: 10rem;
      }
    }

    .player_social_logo {
      width: 2rem;
      margin-right: 2rem;
    }
  }

  p {
    font-size: 1.75rem;
    line-height: 2.75rem;

    @media screen and (min-width: $small-bp) {
      font-size: 1.75rem;
      padding-right: 2rem;
      line-height: 2.75rem;
    }
  }
}
