@import 'utilities/variables';

html {
  font-size: 62.5%;
}

body {
  background: $primary-dark;
  color: $primary-light;
  font-size: 62.5%;
  font-family: 'Lato', sans-serif;
}

#app {
  height: 100%;
}

section {
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

h2 {
  font-size: 5rem;
  text-align: center;
  font-weight: lighter;
}

.sm_logo {
  width: 3rem;
  display: inline-block;
  margin: 0.25rem;

  @media screen and (min-width: $small-bp) {
    width: 6rem;
    display: inline-block;
    margin: 1rem;
  }
}

/* Main splash page content */

#splash {
  background-image: url('img/homepage_background_gray.jpg');
  background-position: center;
  position: relative;
  height: 100vh;

  @media screen and (min-width: $small-bp) {
    min-height: 800px; /* Will this work for smaller screen sizes? */
    height: 100vh;
  }
}

#tagline {
  font-size: 5rem;
  line-height: 5rem;
  text-align: center;
  max-width: 30rem;
  margin: -2rem auto 0rem auto;

  @media screen and (min-width: $small-bp) {
    font-size: 9rem;
    line-height: 9rem;
    max-width: 50rem;
    float: left;
    text-align: left;
    margin-top: 10rem;
  }

  @media screen and (min-width: $medium-bp) {
    font-size: 12rem;
    line-height: 11rem;
    max-width: 70rem;
  }
}

#logo {
  width: 40rem;
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  display: block;

  @media screen and (min-width: $small-bp) {
    float: right;
    margin: 7.5rem 0rem 0rem 0rem;
    width: 35rem;
    order: 2;
  }
}

.content {
  max-width: 150rem;
  margin: 5rem auto 0rem auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;

  @media screen and (min-width: $small-bp) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    max-width: 85rem;
    margin: 0rem auto 0rem auto;
  }

  @media screen and (min-width: $medium-bp) {
    max-width: 105rem;
  }
}

#application_container {
  font-size: 1.5rem;
  margin: 0 auto 10rem auto;
  order: 3;
  text-align: center;

  @media screen and (min-width: $small-bp) {
    margin-bottom: 0rem;
  }
}

.application_button {
  border: solid $primary-light 0.2rem;
  background-color: $primary-light;
  color: $primary-dark;
  word-spacing: 0.4rem;
  line-height: 1.75rem;
  float: left;
  padding: 1rem 2rem;
  width: 16rem;
  cursor: pointer;
  transition: border 0.2s ease, background-color 0.2s ease, color 0.2s ease;

  @media screen and (min-width: $small-bp) {
    width: 20rem;
    padding: 1rem 3rem;
  }
}

.application_button:hover,
.application_button_active {
  border: solid $primary-light 0.2rem;
  background-color: $primary-dark;
  color: $primary-light;
}

.application_button + .application_button {
  margin: 0 0 0 4rem;

  @media screen and (min-width: $small-bp) {
    margin: 0 0 0 8rem;
  }
}

/* Sponsors */
#sponsors {
  min-height: 10rem;
  box-shadow: inset 0rem -13rem 10rem -12rem #000,
    inset 0rem 13rem 10rem -12rem #000;
  position: relative;

  @media screen and (min-width: $small-bp) {
    min-height: 35rem;
  }
}

#sponsors::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('img/Odins_Symbol.svg');
  background-size: 100rem;
  background-repeat: no-repeat;
  background-position: 90% 50%;
  opacity: 0.02;
  z-index: -1;
}

.sponsor {
  margin: 4rem;

  @media screen and (min-width: $small-bp) {
    margin: 8rem;
  }

  img {
    height: 10rem;

    @media screen and (min-width: $small-bp) {
      height: 18rem;
    }
  }
}

#sponsor_container {
  display: flex;
  justify-content: center;
}

/* Merch */

#merch {
  cursor: pointer;
  position: relative;
}

#merch > img {
  width: 100%;
  transition: opacity 0.5s ease-in-out;
}

#merch_t {
  position: absolute;
  left: 0;
}

#merch #merch_t:hover {
  opacity: 0;
}

/* Footer */

footer > a {
  display: table;
  margin: 1rem auto;

  @media screen and (min-width: $small-bp) {
    margin: 2rem auto;
  }
}

#copyright {
  text-align: center;
  margin: 0 auto 1rem auto;
  font-size: 0.65rem;
  width: 26rem;

  @media screen and (min-width: $small-bp) {
    font-size: 1rem;
    width: auto;
  }
}

/* Toast */
.toast-container {
  font-size: 1.25rem;
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}
