@import 'utilities/variables';

/* Games and Teams */

#teams {
  position: relative;
  display: flex;
}

.team {
  flex: 1;
  position: relative;
  cursor: pointer;
  width: 100%;
}

.team:hover {
  .meet_players {
    opacity: 1;
  }
}

.team_name {
  font-size: 3rem;
  text-align: left;
  width: 100%;
  font-weight: 300;
  padding-top: 4rem;
  padding-left: 4rem;
  margin: 0;
  height: 20rem;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  filter: grayscale(100%);
  transition: filter 0.5s ease, height 0.5s ease;

  @media screen and (min-width: $small-bp) {
    font-size: 8rem;
    top: 5rem;
    left: 10rem;
    height: 50rem;
  }

  .meet_players {
    font-size: 1rem;
    text-align: left;
    opacity: 0;
    transition: opacity 0.5s ease;

    @media screen and (min-width: $small-bp) {
      font-size: 3rem;
    }
  }
}

.team_name.active {
  height: 10rem;

  @media screen and (min-width: $small-bp) {
    height: 20rem;
  }
}

.team_name:hover {
  filter: grayscale(0%);
}

#hide_team {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 3rem;
  color: #666;
  margin: 4rem 0rem;

  img {
    height: 2.75rem;
    vertical-align: top;
    margin-top: 0.75rem;
  }

  span {
    margin: 0rem 2rem;
  }
}
