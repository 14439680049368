@import 'utilities/variables';

#social_media_links {
  float: right;
  margin-top: 2.5rem;
}

header {
  padding: 2rem;
  position: fixed;
  top: 0rem;
  left: 0rem;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: transparent;
  z-index: 1;
}

#navbar {
  background-color: rgba(45, 45, 45, 0.8);
}

.nav-link {
  text-align: right;
  margin: 4rem 0rem 2rem 0rem;

  @media screen and (min-width: $small-bp) {
    margin-right: 3rem;
  }
}

.nav-link:hover {
  img {
    filter: drop-shadow(0 0 0.75rem rgb(255, 255, 255));
  }
}

.navbar-light .navbar-toggler {
  border-color: rgba(255, 255, 255, 1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
