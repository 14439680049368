body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Colors */
/* Breakpoints */
#social_media_links {
  float: right;
  margin-top: 2.5rem; }

header {
  padding: 2rem;
  position: fixed;
  top: 0rem;
  left: 0rem;
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  z-index: 1; }

#navbar {
  background-color: rgba(45, 45, 45, 0.8); }

.nav-link {
  text-align: right;
  margin: 4rem 0rem 2rem 0rem; }
  @media screen and (min-width: 992px) {
    .nav-link {
      margin-right: 3rem; } }

.nav-link:hover img {
  -webkit-filter: drop-shadow(0 0 0.75rem white);
          filter: drop-shadow(0 0 0.75rem white); }

.navbar-light .navbar-toggler {
  border-color: white; }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"); }

/* Colors */
/* Breakpoints */
/* Games and Teams */
#teams {
  position: relative;
  display: flex; }

.team {
  flex: 1 1;
  position: relative;
  cursor: pointer;
  width: 100%; }

.team:hover .meet_players {
  opacity: 1; }

.team_name {
  font-size: 3rem;
  text-align: left;
  width: 100%;
  font-weight: 300;
  padding-top: 4rem;
  padding-left: 4rem;
  margin: 0;
  height: 20rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  transition: height 0.5s ease, -webkit-filter 0.5s ease;
  transition: filter 0.5s ease, height 0.5s ease;
  transition: filter 0.5s ease, height 0.5s ease, -webkit-filter 0.5s ease; }
  @media screen and (min-width: 992px) {
    .team_name {
      font-size: 8rem;
      top: 5rem;
      left: 10rem;
      height: 50rem; } }
  .team_name .meet_players {
    font-size: 1rem;
    text-align: left;
    opacity: 0;
    transition: opacity 0.5s ease; }
    @media screen and (min-width: 992px) {
      .team_name .meet_players {
        font-size: 3rem; } }

.team_name.active {
  height: 10rem; }
  @media screen and (min-width: 992px) {
    .team_name.active {
      height: 20rem; } }

.team_name:hover {
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%); }

#hide_team {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 3rem;
  color: #666;
  margin: 4rem 0rem; }
  #hide_team img {
    height: 2.75rem;
    vertical-align: top;
    margin-top: 0.75rem; }
  #hide_team span {
    margin: 0rem 2rem; }

/* Colors */
/* Breakpoints */
.player_container {
  font-size: 3rem;
  text-align: left;
  width: 100%;
  background: #2d2d2d;
  display: flex;
  flex-direction: column;
  padding: 4rem 4rem; }
  @media screen and (min-width: 992px) {
    .player_container {
      flex-direction: row;
      padding: 4rem 16rem 4rem 8rem; } }
  .player_container h1 {
    font-size: 8rem;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .player_container h1 {
        text-align: left; } }
  .player_container .player_profile {
    margin: 0rem auto;
    width: 23rem; }
    @media screen and (min-width: 992px) {
      .player_container .player_profile {
        margin: 2rem 5rem 0rem 0rem;
        width: 30rem; } }
    .player_container .player_profile img {
      width: 100%; }
  .player_container .player_content {
    flex: 1 1; }
    @media screen and (min-width: 992px) {
      .player_container .player_content {
        margin-top: 4rem; } }
  .player_container .player_social_links {
    display: inline-block;
    margin: 1rem 0rem 2rem 0rem;
    width: 100%;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .player_container .player_social_links {
        text-align: left; } }
    .player_container .player_social_links .player_social_link {
      font-size: 2rem;
      display: block;
      color: #ffffff; }
      @media screen and (min-width: 1200px) {
        .player_container .player_social_links .player_social_link {
          display: inline-block; } }
    .player_container .player_social_links .player_social_link + .player_social_link {
      margin: 2rem 0rem; }
      @media screen and (min-width: 1200px) {
        .player_container .player_social_links .player_social_link + .player_social_link {
          margin-left: 10rem; } }
    .player_container .player_social_links .player_social_logo {
      width: 2rem;
      margin-right: 2rem; }
  .player_container p {
    font-size: 1.75rem;
    line-height: 2.75rem; }
    @media screen and (min-width: 992px) {
      .player_container p {
        font-size: 1.75rem;
        padding-right: 2rem;
        line-height: 2.75rem; } }

/* Colors */
/* Breakpoints */
input {
  display: block;
  width: 100%;
  border: 0;
  height: 2.5rem;
  padding-left: 5px; }

label {
  display: block;
  font-size: 2em; }

section#form {
  overflow: visible; }

#ApplicationForm {
  background-color: #2d2d2d;
  padding: 2rem;
  width: 100%;
  max-width: 45rem;
  margin: 1rem auto 3rem auto;
  cursor: auto;
  font-size: 1rem;
  position: relative;
  transition: height 5s ease; }

#submit-close-container {
  text-align: center;
  margin: 0 auto;
  padding-bottom: 1rem;
  width: 95%; }
  #submit-close-container button {
    float: left;
    width: 18rem;
    max-width: 48%; }
  #submit-close-container button + button {
    margin-left: 1rem;
    float: right; }

.form-section {
  margin-bottom: 3rem; }

#first-name {
  float: left;
  width: 48%; }

#last-name {
  float: right;
  width: 48%; }

#proposal {
  width: 100%;
  height: 10rem; }

#recaptcha {
  height: 5rem; }

.submit-close {
  background-color: #ffffff;
  border: 2px solid #ffffff;
  color: #2d2d2d;
  padding: 0.5rem 3rem;
  margin: 2rem auto 0rem auto;
  display: block;
  font-size: 2.5rem;
  cursor: pointer; }

.submit-close:hover {
  background-color: #2d2d2d;
  color: #ffffff; }

.submit-close:disabled,
.submit-close[disabled] {
  opacity: 0.65;
  cursor: not-allowed; }

.application-type-container {
  width: 50%;
  float: left;
  text-align: center;
  margin-bottom: 4rem; }
  .application-type-container input[type='radio'] {
    display: none; }
  .application-type-container label {
    cursor: pointer;
    transition: text-shadow 0.5s ease;
    padding: 2rem 0rem; }
    .application-type-container label input[type='radio'] + span,
    .application-type-container label input[type='radio']:checked + span {
      content: ' ';
      display: inline-block;
      position: relative;
      top: 1rem;
      margin: 0 0 0 1rem;
      width: 3rem;
      height: 3rem;
      background-repeat: none;
      background-size: cover; }
    .application-type-container label input[type='radio'] + span {
      background-image: url(/static/media/radio_unselected.20c23b6c.svg); }
    .application-type-container label input[type='radio']:checked + span {
      background-image: url(/static/media/radio_selected.00456a80.svg); }

.react-slidedown.inner-slidedown {
  transition-duration: 0s;
  transition-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1); }

/* Colors */
/* Breakpoints */
html {
  font-size: 62.5%; }

body {
  background: #2d2d2d;
  color: #ffffff;
  font-size: 62.5%;
  font-family: 'Lato', sans-serif; }

#app {
  height: 100%; }

section {
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden; }

h2 {
  font-size: 5rem;
  text-align: center;
  font-weight: lighter; }

.sm_logo {
  width: 3rem;
  display: inline-block;
  margin: 0.25rem; }
  @media screen and (min-width: 992px) {
    .sm_logo {
      width: 6rem;
      display: inline-block;
      margin: 1rem; } }

/* Main splash page content */
#splash {
  background-image: url(/static/media/homepage_background_gray.d90b37f4.jpg);
  background-position: center;
  position: relative;
  height: 100vh; }
  @media screen and (min-width: 992px) {
    #splash {
      min-height: 800px;
      /* Will this work for smaller screen sizes? */
      height: 100vh; } }

#tagline {
  font-size: 5rem;
  line-height: 5rem;
  text-align: center;
  max-width: 30rem;
  margin: -2rem auto 0rem auto; }
  @media screen and (min-width: 992px) {
    #tagline {
      font-size: 9rem;
      line-height: 9rem;
      max-width: 50rem;
      float: left;
      text-align: left;
      margin-top: 10rem; } }
  @media screen and (min-width: 1200px) {
    #tagline {
      font-size: 12rem;
      line-height: 11rem;
      max-width: 70rem; } }

#logo {
  width: 40rem;
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  display: block; }
  @media screen and (min-width: 992px) {
    #logo {
      float: right;
      margin: 7.5rem 0rem 0rem 0rem;
      width: 35rem;
      order: 2; } }

.content {
  max-width: 150rem;
  margin: 5rem auto 0rem auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%; }
  @media screen and (min-width: 992px) {
    .content {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      max-width: 85rem;
      margin: 0rem auto 0rem auto; } }
  @media screen and (min-width: 1200px) {
    .content {
      max-width: 105rem; } }

#application_container {
  font-size: 1.5rem;
  margin: 0 auto 10rem auto;
  order: 3;
  text-align: center; }
  @media screen and (min-width: 992px) {
    #application_container {
      margin-bottom: 0rem; } }

.application_button {
  border: solid #ffffff 0.2rem;
  background-color: #ffffff;
  color: #2d2d2d;
  word-spacing: 0.4rem;
  line-height: 1.75rem;
  float: left;
  padding: 1rem 2rem;
  width: 16rem;
  cursor: pointer;
  transition: border 0.2s ease, background-color 0.2s ease, color 0.2s ease; }
  @media screen and (min-width: 992px) {
    .application_button {
      width: 20rem;
      padding: 1rem 3rem; } }

.application_button:hover,
.application_button_active {
  border: solid #ffffff 0.2rem;
  background-color: #2d2d2d;
  color: #ffffff; }

.application_button + .application_button {
  margin: 0 0 0 4rem; }
  @media screen and (min-width: 992px) {
    .application_button + .application_button {
      margin: 0 0 0 8rem; } }

/* Sponsors */
#sponsors {
  min-height: 10rem;
  box-shadow: inset 0rem -13rem 10rem -12rem #000, inset 0rem 13rem 10rem -12rem #000;
  position: relative; }
  @media screen and (min-width: 992px) {
    #sponsors {
      min-height: 35rem; } }

#sponsors::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(/static/media/Odins_Symbol.205795f3.svg);
  background-size: 100rem;
  background-repeat: no-repeat;
  background-position: 90% 50%;
  opacity: 0.02;
  z-index: -1; }

.sponsor {
  margin: 4rem; }
  @media screen and (min-width: 992px) {
    .sponsor {
      margin: 8rem; } }
  .sponsor img {
    height: 10rem; }
    @media screen and (min-width: 992px) {
      .sponsor img {
        height: 18rem; } }

#sponsor_container {
  display: flex;
  justify-content: center; }

/* Merch */
#merch {
  cursor: pointer;
  position: relative; }

#merch > img {
  width: 100%;
  transition: opacity 0.5s ease-in-out; }

#merch_t {
  position: absolute;
  left: 0; }

#merch #merch_t:hover {
  opacity: 0; }

/* Footer */
footer > a {
  display: table;
  margin: 1rem auto; }
  @media screen and (min-width: 992px) {
    footer > a {
      margin: 2rem auto; } }

#copyright {
  text-align: center;
  margin: 0 auto 1rem auto;
  font-size: 0.65rem;
  width: 26rem; }
  @media screen and (min-width: 992px) {
    #copyright {
      font-size: 1rem;
      width: auto; } }

/* Toast */
.toast-container {
  font-size: 1.25rem; }

.clearfix::after {
  content: '';
  clear: both;
  display: table; }

