@import 'utilities/variables';

$radioSelected: url('./img/radio_selected.svg');
$radioUnselected: url('./img/radio_unselected.svg');

input {
  display: block;
  width: 100%;
  border: 0;
  height: 2.5rem;
  padding-left: 5px;
}

label {
  display: block;
  font-size: 2em;
}

section#form {
  overflow: visible;
}

#ApplicationForm {
  background-color: $primary-dark;
  padding: 2rem;
  width: 100%;
  max-width: 45rem;
  margin: 1rem auto 3rem auto;
  cursor: auto;
  font-size: 1rem;
  position: relative;
  transition: height 5s ease;
}

#submit-close-container {
  text-align: center;
  margin: 0 auto;
  padding-bottom: 1rem;
  width: 95%;

  button {
    float: left;
    width: 18rem;
    max-width: 48%;
  }
  button + button {
    margin-left: 1rem;
    float: right;
  }
}

.form-section {
  margin-bottom: 3rem;
}
#first-name {
  float: left;
  width: 48%;
}
#last-name {
  float: right;
  width: 48%;
}

#proposal {
  width: 100%;
  height: 10rem;
}

#recaptcha {
  height: 5rem;
}

.submit-close {
  background-color: $primary-light;
  border: 2px solid $primary-light;
  color: $primary-dark;
  padding: 0.5rem 3rem;
  margin: 2rem auto 0rem auto;
  display: block;
  font-size: 2.5rem;
  cursor: pointer;
}

.submit-close:hover {
  background-color: $primary-dark;
  color: $primary-light;
}

.submit-close:disabled,
.submit-close[disabled] {
  opacity: 0.65;
  cursor: not-allowed;
}

.application-type-container {
  width: 50%;
  float: left;
  text-align: center;
  margin-bottom: 4rem;

  input[type='radio'] {
    display: none;
  }

  label {
    cursor: pointer;
    transition: text-shadow 0.5s ease;
    padding: 2rem 0rem;

    input[type='radio'] + span,
    input[type='radio']:checked + span {
      content: ' ';
      display: inline-block;
      position: relative;
      top: 1rem;
      margin: 0 0 0 1rem;
      width: 3rem;
      height: 3rem;
      background-repeat: none;
      background-size: cover;
    }

    input[type='radio'] + span {
      background-image: $radioUnselected;
    }
    input[type='radio']:checked + span {
      background-image: $radioSelected;
    }
  }
}

.react-slidedown.inner-slidedown {
  transition-duration: 0s;
  transition-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1);
}
